import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import { db } from "../../services/Firebase";
import {
  collection,
  doc,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  startAfter,
} from "firebase/firestore";
import Pagination from "../common/Pagination";
import { CSVLink } from "react-csv";
export default function Reports(props) {
  const [loader, setLoader] = useState(false);

  const [reportsData, setReportsData] = useState([]);
  console.log(reportsData, "data");
  const [lastVisible, setLastVisible] = useState(null); // Track last visible document for pagination
  const [pageSize] = useState(10); // Number of users to fetch per page
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState("");
  const headers = [
    { label: "NAME", key: "userName" },
    { label: "EMAIL", key: "email" },
    { label: "Description", key: "description" },
  ];
  const fetchReports = async (page) => {
    setLoader(true);
    try {
      const reportsCollectionRef = collection(db, "reports");
      let queryRef;

      if (page === 0 || !page) {
        queryRef = query(
          reportsCollectionRef,
          orderBy("userName", "desc"),
          limit(pageSize)
        );
        setLastVisible(null); // Reset lastVisible when fetching the first page
      } else {
        queryRef = query(
          reportsCollectionRef,
          orderBy("userName", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }
      const querySnapshot = await getDocs(queryRef);
      const data = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      // Update users state only if there are new users fetched
      if (data.length > 0) {
        setReportsData(data);
        const lastReport = querySnapshot.docs[querySnapshot.docs.length - 1];
        setLastVisible(lastReport);
      }
      // Fetch total count separately
      const totalQuerySnapshot = await getDocs(reportsCollectionRef);
      const totalCount = totalQuerySnapshot.size;

      // Update pagination-related states
      setPageCount(Math.ceil(totalCount / pageSize));
      setListLength(totalCount);
      // setReportsData(data);
      setLoader(false);
    } catch (error) {
      console.error("Error fetching reports:", error);
      setLoader(false);
    }
  };
  const fetchUser = async (userId) => {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDocSnapshot = await getDoc(userDocRef);
      if (userDocSnapshot.exists()) {
        return userDocSnapshot.data();
      } else {
        console.log("User not found");
        return null;
      }
    } catch (error) {
      console.error("Error fetching user:", error);
      throw error;
    }
  };

  useEffect(() => {
    fetchReports(currentPage); // Call the fetchData function when the component mounts
  }, [currentPage]);
  return (
    <div>
      <PageTitle activeMenu="Reports List" motherMenu="Reports" />
      <Col>
        <Card>
          <Card.Header>
            <div></div>
            <div className="text-end">
              <CSVLink
                filename={"Reports-file.csv"}
                data={reportsData}
                headers={headers}
              >
                <button
                  className="btn btn-primary"
                  style={{
                    backgroundColor: "#3C94E6",
                    borderColor: "#3C94E6",
                    paddingBottom: "10px",
                    paddingTop: "10px",
                  }}
                >
                  Export <i class="flaticon-381-download"></i>
                </button>
              </CSVLink>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>Description</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportsData?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.userName}</td>
                      <td>{item?.email}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
            {reportsData?.length === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {reportsData?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * pageSize + 1} to{" "}
                    {listLength > (currentPage + 1) * pageSize
                      ? (currentPage + 1) * pageSize
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
