/// Menu
import MetisMenu from "metismenujs";
import React, { Component } from "react";
/// Scroll
import PerfectScrollbar from "react-perfect-scrollbar";
/// Link
import { Link } from "react-router-dom";

import icon1 from "../../../images/icon1.png";

class MM extends Component {
  componentDidMount() {
    this.$el = this.el;
    this.mm = new MetisMenu(this.$el);
  }
  componentWillUnmount() {}
  render() {
    return (
      <div className="mm-wrapper">
        <ul className="metismenu" ref={(el) => (this.el = el)}>
          {this.props.children}
        </ul>
      </div>
    );
  }
}

class SideBar extends Component {
  /// Open menu
  componentDidMount() {
    // sidebar open/close
    var btn = document.querySelector(".nav-control");
    var aaa = document.querySelector("#main-wrapper");
    function toggleFunc() {
      return aaa.classList.toggle("menu-toggle");
    }
    btn.addEventListener("click", toggleFunc);
  }
  state = {
    loveEmoji: false,
  };
  render() {
    /// Path
    let path = window.location.pathname;
    path = path.split("/");
    path = path[path.length - 1];

    /// Active menu
    let dashBoard = [
        "",
        "analytics",
        "events",
        "order-list",
        "general-customers",
        "reviews",
        "task",
      ],
      users = ["user-management"],
      report = ["reports"],
      transactions = ["transactions"],
      notification = ["push-notification"];
    

    return (
      <div className="deznav">
        <PerfectScrollbar className="deznav-scroll">
          <MM className="metismenu" id="menu">
            <li className={`${dashBoard.includes(path) ? "mm-active" : ""}`}>
              <Link className=" ai-icon" to="/">
                <i className="flaticon-381-networking"></i>
              
                <span className="nav-text">Dashboard</span>
              </Link>
            </li>

            <li className={`${users.includes(path) ? "mm-active" : ""}`}>
              <Link to="user-management" className="ai-icon">
                <i className="flaticon-381-user-9"></i>
             
                <span className="nav-text">User Management</span>
              </Link>
            </li>
            <li className={`${report.includes(path) ? "mm-active" : ""}`}>
              <Link to="reports" className="ai-icon">
                <i className="flaticon-381-warning-1"></i>
             
                <span className="nav-text">Reports</span>
              </Link>
            </li>
            <li className={`${transactions.includes(path) ? "mm-active" : ""}`}>
              <Link to="transactions" className="ai-icon">
                <i className="flaticon-381-list"></i>
             
                <span className="nav-text">Transactions</span>
              </Link>
            </li>
            <li className={`${notification.includes(path) ? "mm-active" : ""}`}>
              <Link to="push-notification" className="ai-icon">
                <i className="flaticon-381-notification"></i>
                <span className="nav-text">Push Notification</span>
              </Link>
            </li>
            
            {/* <li className={`${artical.includes(path) ? "mm-active" : ""}`}>
              <Link className="has-arrow ai-icon" to="#">
                {/* <i className="flaticon-381-television"></i> *
                <img src={articalIcon} ></img>
                <span className="nav-text pl-4">CMS</span>
              </Link>
              <ul>
                <li>
                  <Link
                    className={`${path === "article-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/article-management"
                  >
                    Article Management
                  </Link>
                </li>
                <li>
                  <Link
                    className={`${path === "advertise-management" ? "mm-active" : ""}`}
                    onClick={() => this.props.onClick()}
                    to="/advertise-management"
                  >
                    Advertise Management
                  </Link>
                </li>
              </ul>
            </li>
          
            */}
          </MM>
        </PerfectScrollbar>
      </div>
    );
  }
}

export default SideBar;
