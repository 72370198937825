import React from "react";
import "./buttonLoader.css";

function ButtonLoader({ stroke }) {
  return (
    <svg viewBox="25 25 50 50" className="button-loader-svg">
      <circle r="20" cy="50" cx="50" stroke={"#fff"}></circle>
    </svg>
  );
}

export default ButtonLoader;
