import React, { useEffect, useState } from "react";
import { Card, Col, Table } from "react-bootstrap";
import moment from "moment";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import { db } from "../../services/Firebase";
import { collection, getDocs } from "firebase/firestore";
export default function UserDetails(props) {
  const [loader, setLoader] = useState(false);
  const userId = props.location.state;
  const [reportsData, setReportsData] = useState([]);

  const fetchReports = async () => {
    setLoader(true);
    try {
      // Reference to the "reports" collection nested inside a specific user document
      const reportsCollectionRef = collection(db, "users", userId, "reports");

      // Fetch all documents from the "reports" collection
      const querySnapshot = await getDocs(reportsCollectionRef);

      // Map over the query snapshot to extract document data
      const reportsData = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // You can set state, log, or perform any operations with the reportsData here
      console.log(reportsData);
      setReportsData(reportsData);
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.error("Error fetching reports:", error);
    }
  };
  useEffect(() => {
    fetchReports(); // Call the fetchData function when the component mounts
  }, []);
  return (
    <div>
      <PageTitle activeMenu="User Deatils" motherMenu="Users" />
      <Col>
        <Card>
          <Card.Header>
            <h3 className="text-black">Reports</h3>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>Description</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {reportsData?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.userName}</td>
                      <td>{item?.email}</td>
                      <td>{item.description}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
