import axios from "axios";

const axiosInstance = axios.create({
  baseURL: `http://3.215.13.97:5000/`,
});

axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem("shootingToken");
    if (token) {
      config.headers["Authorization"] =  `Bearer ${token}`;
    }
    config.headers["Content-Type"] = "application/json";
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      // localStorage.removeItem("shootingToken");
      // window.location.replace("/");
    }
    return Promise.reject(error);
  }
);
export default axiosInstance;
// const axiosInstance = axios.create({
//     baseURL: `https://react-course-b798e-default-rtdb.firebaseio.com/`,
// });

// axiosInstance.interceptors.request.use((config) => {
//     const state = store.getState();
//     const token = state.auth.auth.idToken;
//     config.params = config.params || {};
//     config.params['auth'] = token;
// 	console.log(config);
//     return config;
// });

// export default axiosInstance;
