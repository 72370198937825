import React, { useEffect, useState } from "react";
import { Card, Table, Badge, Col, Dropdown, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import { auth, db } from "../../services/Firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  limit,
  startAfter,
  where,
  Timestamp,
} from "firebase/firestore";
import blankImg from "../../images/blank-profile-picture.webp";
import Pagination from "../common/Pagination";
import moment from "moment";
export default function TransactionList(props) {
  const [search, setSearch] = useState("");
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [lastVisible, setLastVisible] = useState(null); // Track last visible document for pagination
  const [pageSize] = useState(10); // Number of users to fetch per page
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState("");
  console.log();
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  console.log(search, "search out function");

  const fetchData = async (page, startDate, endDate, search) => {
    try {
      setLoader(true);
      const usersCollectionRef = collection(db, "transitions");
      let queryRef = usersCollectionRef; // Initialize the query with the collection reference

      // Apply date filters if start date and/or end date are provided
      if (startDate && endDate) {
        const startDateObject = new Date(startDate);
        const endDateObject = new Date(endDate);
        endDateObject.setHours(23, 59, 59, 999);
        // Convert Date objects to Timestamp objects
        const startDateTimestamp = Timestamp.fromDate(startDateObject);
        const endDateTimestamp = Timestamp.fromDate(endDateObject);
        // endDateTimestamp.setHours(23, 59, 59, 999);

        // Apply both start and end date filters
        queryRef = query(
          queryRef,
          where("timestamp", ">=", startDateTimestamp),
          where("timestamp", "<", endDateTimestamp)
        );
      }
      // Apply search query filter
      if (search) {
        queryRef = query(queryRef, where("orderId", "==", search));
      }

      // Fetch only document references
      const querySnapshot = await getDocs(queryRef);

      // Extract document IDs
      const documentIds = querySnapshot.docs.map((doc) => doc.id);

      // Count the total number of documents
      const totalCount = documentIds.length;

      // Update pagination-related states
      setPageCount(Math.ceil(totalCount / pageSize));
      setListLength(totalCount);

      // Apply ordering and pagination
      if (page === 0 || !page) {
        queryRef = query(
          queryRef,
          orderBy("timestamp", "desc"),
          limit(pageSize)
        );
        setLastVisible(null); // Reset lastVisible when fetching the first page
      } else {
        queryRef = query(
          queryRef,
          orderBy("timestamp", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }

      const usersSnapshot = await getDocs(queryRef);
      const userData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Update users state only if there are new users fetched
      if (userData.length > 0) {
        console.log(userData, "user data");
        setUsers(userData);
        const lastUser = usersSnapshot.docs[usersSnapshot.docs.length - 1];
        setLastVisible(lastUser);
      } else {
        setUsers([]);
      }

      setLoader(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoader(false);
    }
  };

  useEffect(() => {
    fetchData(currentPage, startDate, endDate); // Call the fetchData function when the component mounts
  }, [currentPage, endDate]);

  const handleReset = () => {
    setStartDate(""); // Reset startDate
    setEndDate(""); // Reset endDate
    fetchData(currentPage, "", ""); // Fetch data again with empty date values
  };
  const handleSearchChange = () => {
    fetchData(0, startDate, endDate, search); // Fetch data with the new search query
  };
  return (
    <div>
      <PageTitle activeMenu="Transactions List" motherMenu="Transactions" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-start mb-2">
              <div
                className="input-group border bg-white input-group-sm w-50"
                style={{ borderRadius: "8px" }}
              >
                <input
                  type="text"
                  name="table_search"
                  className="form-control float-right border-0 search-input"
                  placeholder="Search"
                  // onKeyDown={(e) => {
                  //   console.log(e.key);
                  //   if (e.key === "Enter") {
                  //     handleFetch();
                  //     // setCurrentPage(0);
                  //   }
                  // }}
                  onChange={(e) => setSearch(e.target.value)}
                />
                <div className="input-group-append">
                  <button
                    type="button"
                    className="btn btn-default"
                    onClick={handleSearchChange}
                  >
                    <i className="fa fa-search" />
                  </button>
                </div>
              </div>
              <button
                type="button"
                disabled={!endDate}
                className="btn btn-primary py-2"
                onClick={handleReset}
              >
                Reset
              </button>
            </div>

            <div className="d-flex">
              <div className="mr-3">
                <label>Start Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                />
              </div>
              <div>
                <label>End Date</label>
                <input
                  type="date"
                  className="form-control"
                  value={endDate}
                  onChange={(e) => setEndDate(e.target.value)}
                  min={startDate}
                />
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>Order Id</strong>
                    </th>
                    <th>
                      <strong>subscription Id</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>Date</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td>{item?.orderId}</td>
                      <td>{item?.subscriptionId}</td>
                      <td>{item?.displayName}</td>
                      <td>{item?.email}</td>
                      <td>
                        {moment
                          .unix(item?.timestamp?.seconds)
                          .milliseconds(item?.timestamp?.nanoseconds / 1000000)
                          .format("MMMM D, YYYY [at] hh:mm A")}
                      </td>
                      <td>{item?.price ? item?.price : "--"}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {users?.length === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}

            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * pageSize + 1} to{" "}
                    {listLength > (currentPage + 1) * pageSize
                      ? (currentPage + 1) * pageSize
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
