import React, { useState } from "react";
import PageTitle from "../layouts/PageTitle";
import { notifyTopRight } from "../common/Toaster";
import Spinner from "../common/Spinner";

export default function PushNotification() {
  let errorsObj = { title: "", body: "" };
  const [errors, setErrors] = useState(errorsObj);
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [loader, setLoader] = useState(false);
// console.log(process.env.REACT_APP_FCM_TOKEN,"token")
  async function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
    if (title.trim() === "") {
      errorObj.title = "Title is Required";
      error = true;
    }
    if (body.trim() === "") {
      errorObj.body = "Body is Required";
      error = true;
    }

    setErrors(errorObj);
    if (error) {
      return;
    }
    setLoader(true);
    try {
      const request = require("request");
      const options = {
        url: "https://fcm.googleapis.com/fcm/send",
        method: "POST",
        headers: {
          Authorization:
            "key=" + process.env.REACT_APP_FCM_TOKEN,
          "Content-Type": "application/json",
        },

        json: {
          to: "/topics/AdminNotification",
          // "/topics/stagingUsersOnly", // for staging
          // "/topics/all", // Assuming all users are subscribed to the 'all' topic ---- for live server

          data: {
            title: title,
            body: body,
            push_type: 1,
          },
          // notification: {
          //   title: title,
          //   body: body,
          //   push_type: 1,
          // },
        },
      };
      request(options, function (error, response, body) {
        if (!error && response.statusCode === 200) {
          console.log(body, "200 status");
          notifyTopRight("Notification sent successfully.");
          setTitle("");
          setBody("");
          setLoader(false);
        } else {
          console.log(error, "error");
          setLoader(false);
        }
      });
    } catch (error) {
      console.error("Error :", error);
      setLoader(false);
    }
  }
  return (
    <div>
      {" "}
      <PageTitle activeMenu="Push Notifications" motherMenu="Notifications" />
      <div className="col-lg-8 col-md-7 ">
        <div className="authincation-content text-black p-5">
          <div className="mb-4">
            <h3 className="mb-1 font-w600 text-black ">Add Details</h3>
          </div>
          {loader ? (
              <Spinner />
            ) : (
          <form onSubmit={onSubmit}>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Title</strong>
              </label>
              <input
                type="text"
                className="form-control"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                placeholder="Enter title"
              />
              {errors.title && (
                <div className="text-danger fs-12">{errors.title}</div>
              )}
            </div>
            <div className="form-group">
              <label className="mb-2 ">
                <strong className="">Body</strong>
              </label>
              <textarea
                // type="text"
                className="form-control"
                rows={5}
                value={body}
                onChange={(e) => setBody(e.target.value)}
                placeholder="Write something here.."
              />
              {errors.body && (
                <div className="text-danger fs-12">{errors.body}</div>
              )}
            </div>
            <div className="text-center">
           
              <button type="submit" className="btn btn-primary btn-block">
                Send
              </button>
           
            </div>
          </form>
           )}
        </div>
      </div>
    </div>
  );
}
