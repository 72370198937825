import React, { useEffect, useState } from "react";
import { Card, Table, Badge, Col, Dropdown, Button } from "react-bootstrap";
import PageTitle from "../layouts/PageTitle";
import Spinner from "../common/Spinner";
import { auth, db } from "../../services/Firebase";
import {
  collection,
  doc,
  getDocs,
  orderBy,
  query,
  updateDoc,
  limit,
  startAfter,
  where,
} from "firebase/firestore";
import blankImg from "../../images/blank-profile-picture.webp";
import Pagination from "../common/Pagination";
export default function UserManagement(props) {
  const defaultValue = props.location.state;
  const [loader, setLoader] = useState(false);
  const [users, setUsers] = useState([]);
  const [lastVisible, setLastVisible] = useState(null); // Track last visible document for pagination
  const [pageSize] = useState(10); // Number of users to fetch per page
  const [pageCount, setPageCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(0);
  const [listLength, setListLength] = useState("");
  const [search, setSearch] = useState("");
  console.log(search, "search  function k bahae");

  const [premium, setPremium] = useState(defaultValue ? defaultValue : "all");
  // console.log(premium, "premium value");
  const svg1 = (
    <svg width="20px" height="20px" viewBox="0 0 24 24" version="1.1">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <rect x="0" y="0" width="24" height="24"></rect>
        <circle fill="#000000" cx="5" cy="12" r="2"></circle>
        <circle fill="#000000" cx="12" cy="12" r="2"></circle>
        <circle fill="#000000" cx="19" cy="12" r="2"></circle>
      </g>
    </svg>
  );
  // const fetchData = async (page) => {
  //   try {
  //     setLoader(true);
  //     const usersCollectionRef = collection(db, "users");
  //     let queryRef = usersCollectionRef;
  //     if (page === 0 || !page) {
  //       queryRef = query(
  //         usersCollectionRef,
  //         orderBy("displayName", "desc"),
  //         limit(pageSize)
  //       );
  //       setLastVisible(null); // Reset lastVisible when fetching the first page
  //     } else {
  //       queryRef = query(
  //         usersCollectionRef,
  //         orderBy("displayName", "desc"),
  //         startAfter(lastVisible),
  //         limit(pageSize)
  //       );
  //     }

  //     const querySnapshot = await getDocs(queryRef);
  //     const userData = querySnapshot.docs.map((doc) => ({
  //       id: doc.id,
  //       ...doc.data(),
  //     }));
  //     console.log(userData, "userdata");
  //     // Update users state only if there are new users fetched
  //     if (userData.length > 0) {
  //       setUsers(userData);
  //       const lastUser = querySnapshot.docs[querySnapshot.docs.length - 1];
  //       setLastVisible(lastUser);
  //     }

  //     // Fetch total count separately
  //     const totalQuerySnapshot = await getDocs(usersCollectionRef);
  //     const totalCount = totalQuerySnapshot.size;

  //     // Update pagination-related states
  //     setPageCount(Math.ceil(totalCount / pageSize));
  //     setListLength(totalCount);

  //     setLoader(false);
  //   } catch (error) {
  //     console.error("Error fetching users:", error);
  //     setLoader(false);
  //   }
  // };
  const fetchData = async (page, premium, search) => {
    try {
      setLoader(true);
      const usersCollectionRef = collection(db, "users");
      let queryRef = usersCollectionRef;

      // Apply premium filter if premium is explicitly true
      if (premium === true) {
        queryRef = query(queryRef, where("premium", "==", true));
      } else if (premium === false) {
        // Apply non-premium filter if premium is explicitly false
        queryRef = query(queryRef, where("premium", "==", false));
      }
      console.log(search, "search");
      // Apply search query filter
      // if (search) {
      //   queryRef = query(queryRef, where("displayName", "",search));
      // }
      // Apply search query filter
      if (search) {
        // Modify the search query to perform a partial match
        const searchQuery = search.toLowerCase(); // Convert the search string to lowercase for case-insensitive search
        const startAt = searchQuery;
        const endAt = searchQuery + "\uf8ff"; // '\uf8ff' is a Unicode character that ensures endAt is greater than any character in the ASCII character set
        queryRef = query(
          queryRef,
          where("displayName", ">=", startAt),
          where("displayName", "<=", endAt)
        );
      }

      // Fetch only document references
      const querySnapshot = await getDocs(queryRef);

      // Extract document IDs
      const documentIds = querySnapshot.docs.map((doc) => doc.id);

      // Count the total number of documents
      const totalCount = documentIds.length;

      // Update pagination-related states
      setPageCount(Math.ceil(totalCount / pageSize));
      setListLength(totalCount);
      // Apply pagination
      if (page === 0 || !page) {
        queryRef = query(
          queryRef,
          orderBy("displayName", "desc"),
          limit(pageSize)
        );
        setLastVisible(null); // Reset lastVisible when fetching the first page
      } else {
        queryRef = query(
          queryRef,
          orderBy("displayName", "desc"),
          startAfter(lastVisible),
          limit(pageSize)
        );
      }
      const usersSnapshot = await getDocs(queryRef);
      const userData = usersSnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));

      // Update users state only if there are new users fetched
      if (userData.length > 0) {
        console.log(userData, "user data");
        setUsers(userData);
        const lastUser = usersSnapshot.docs[usersSnapshot.docs.length - 1];
        setLastVisible(lastUser);
      }

      setLoader(false);
    } catch (error) {
      console.error("Error fetching users:", error);
      setLoader(false);
    }
  };

  const handleBlockToggle = async (userId, isBlocked) => {
    try {
      // Reference to the user document
      const userDocRef = doc(db, "users", userId);

      // Update the user document with block status
      await updateDoc(userDocRef, { block: !isBlocked });

      // Update the local state to reflect the change
      fetchData();
    } catch (error) {
      console.error("Error toggling block status:", error);
    }
  };
  useEffect(() => {
    fetchData(currentPage, premium); // Call the fetchData function when the component mounts
  }, [currentPage, premium]);

  const handleSearchChange = () => {
    fetchData(0, premium, search); // Fetch data with the new search query
  };
  return (
    <div>
      <PageTitle activeMenu="Users List" motherMenu="Users" />
      <Col>
        <Card>
          <Card.Header className="d-block">
            <div className="d-flex justify-content-between align-items-start">
              <div className="col-6" style={{ flexGrow: 1 }}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <div
                    className="input-group border bg-white input-group-sm"
                    style={{ borderRadius: "8px" }}
                  >
                    <input
                      type="text"
                      name="table_search"
                      className="form-control float-right border-0 search-input"
                      placeholder="Search"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                    <div className="input-group-append">
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={handleSearchChange}
                      >
                        <i className="fa fa-search" />
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-start align-items-center ">
                <label className="w-100">Filter By Type :</label>
                <select
                  className="form-control"
                  defaultValue={defaultValue}
                  value={String(premium)}
                  onChange={(e) =>
                    setPremium(
                      e.target.value === "all"
                        ? null
                        : JSON.parse(e.target.value)
                    )
                  } // Convert string to boolean
                >
                  <option hidden>Select..</option>
                  <option value="all">All</option>
                  <option value={"true"}>Premium</option>
                  <option value={"false"}>Non Premium</option>
                </select>
              </div>
            </div>
          </Card.Header>
          <Card.Body>
            {loader ? (
              <Spinner />
            ) : (
              <Table responsive>
                <thead className="text-black">
                  <tr>
                    <th>
                      <strong>Image</strong>
                    </th>
                    <th>
                      <strong>NAME</strong>
                    </th>
                    <th>
                      <strong>EMAIL</strong>
                    </th>
                    <th>
                      <strong>User Type</strong>
                    </th>
                    <th>
                      <strong>Status</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {users?.map((item, i) => (
                    <tr key={i}>
                      <td>
                        <img
                          src={
                            item?.photoUrl !== "null"
                              ? item?.photoUrl
                              : blankImg
                          }
                          width={80}
                          height={80}
                          className="rounded"
                        />
                      </td>
                      <td>{item?.displayName}</td>
                      <td>{item?.email}</td>
                      <td>
                        {item?.premium ? (
                          <Badge variant="success light">Premium</Badge>
                        ) : (
                          <Badge variant="danger light">Non Premium</Badge>
                        )}
                      </td>
                      <td>
                        {item.block ? (
                          <Badge variant="danger light">Disable</Badge>
                        ) : (
                          <Badge variant="success light">Enable</Badge>
                        )}
                      </td>
                      <td>
                        {" "}
                        <Dropdown>
                          <Dropdown.Toggle
                            variant="info light"
                            className="light sharp btn btn-info i-false"
                          >
                            {svg1}
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                            {item.block ? (
                              <Dropdown.Item
                                onClick={() =>
                                  handleBlockToggle(item.id, item.block)
                                }
                              >
                                Enable
                              </Dropdown.Item>
                            ) : (
                              <Dropdown.Item
                                onClick={() =>
                                  handleBlockToggle(item.id, item.block)
                                }
                              >
                                Disable
                              </Dropdown.Item>
                            )}
                          </Dropdown.Menu>
                        </Dropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}

            {users?.length === 0 && !loader && (
              <div className="justify-content-center d-flex my-5 ">
                Sorry, Data Not Found!
              </div>
            )}
            {users?.length !== 0 && (
              <div className="card-footer clearfix">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="dataTables_info">
                    Showing {currentPage * pageSize + 1} to{" "}
                    {listLength > (currentPage + 1) * pageSize
                      ? (currentPage + 1) * pageSize
                      : listLength}{" "}
                    of {listLength} entries
                  </div>
                  <Pagination
                    pageCount={pageCount}
                    pageValue={currentPage}
                    setPage={setCurrentPage}
                  />
                </div>
              </div>
            )}
          </Card.Body>
        </Card>
      </Col>
    </div>
  );
}
