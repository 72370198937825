import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAuth } from "firebase/auth";
const firebaseConfig = {
  apiKey: "AIzaSyDQbgfOxPYfIl3HW4_OYl07JfNlBFzXFRo",
  authDomain: "chat-saver-e6778.firebaseapp.com",
  projectId: "chat-saver-e6778",
  storageBucket: "chat-saver-e6778.appspot.com",
  messagingSenderId: "751135863155",
  appId: "1:751135863155:web:abde96f1e7129c67c11506",
  measurementId: "G-KC2N68ZY46",
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app); // Get the auth instance
const db = getFirestore(app);
export { auth, db};
// export default db ;
